<template>
	<div class="RpaManage">
		<a-spin size="large" :spinning="loading" />
		<div class="header">
			<a-button type="primary" @click="$router.push('/manage/rpa/create')">
				<img src="@/assets/img/rpa/manage/icon_add.svg" alt="" />
				<span>创建任务流程</span>
			</a-button>
			<a-select v-model="selectedGroupId" style="width: 160px">
				<a-select-option :value="''">全部</a-select-option>
				<a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
			</a-select>
			<a-button class="group" :disabled="!checkedCount" @click="moveGroupDialogVisible = true">
				<img src="@/assets/img/rpa/manage/icon_move_group.svg" alt="" />
				<span>移动分组</span>
			</a-button>
			<a-button class="group" @click="groupManageDialogVisible = true">
				<img src="@/assets/img/rpa/manage/icon_group_manage.svg" alt="" />
				<span>分组管理</span>
			</a-button>
			<a-input v-model="keywords" allow-clear placeholder="搜索任务流程" style="width: 300px">
				<a-icon type="search" slot="prefix" @click="search" />
			</a-input>
		</div>
		<div class="list">
			<div class="top">
				<a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"> 全选 </a-checkbox>
				<div class="count">
					任务流程数 : <span>{{ total }}</span>
				</div>
			</div>
			<ul class="task_container">
				<li v-for="item in tasks" :key="item.id" :class="{ topping: item.sort }">
					<div class="header">
						<div class="left">
							<a-checkbox v-model="item.checked" @change="onChangeChange" />
							<span>{{ item.name }}</span>
						</div>
						<div class="right">
							<img @click="$router.push({ path: `/manage/rpa/create/${item.id}` })" src="@/assets/img/rpa/create/icon_edit.svg" alt="" />
							<a-popover overlayClassName="taskListItemOptionsMenu">
								<img src="@/assets/img/rpa/manage/icon_ellipsis.svg" alt="" />
								<template slot="content">
									<a-menu style="border: none">
										<a-menu-item @click="copyTask(item.id)">
											<img src="@/assets/img/rpa/create/icon_copy.svg" alt="" />
											<span>复制模板</span>
										</a-menu-item>
										<a-menu-item v-if="item.sort" @click="topTask(item.id)">
											<img src="@/assets/img/rpa/manage/icon_cancel_top.svg" alt="" />
											<span>取消置顶</span>
										</a-menu-item>
										<a-menu-item v-else @click="topTask(item.id)">
											<img src="@/assets/img/rpa/manage/icon_top.svg" alt="" />
											<span>置顶</span>
										</a-menu-item>
										<a-menu-item @click="deleteTask(item.id)">
											<img src="@/assets/img/rpa/create/icon_delete.svg" alt="" />
											<span>删除</span>
										</a-menu-item>
									</a-menu>
								</template>
							</a-popover>
						</div>
					</div>
					<p class="remark">{{ item.remark || "暂无备注" }}</p>
					<div class="footer">
						<div class="group">{{ item.group }}</div>
						<a-button type="primary">启动</a-button>
					</div>
				</li>
			</ul>
			<a-pagination v-model="page" @change="pageChange" :total="total" show-size-changer @showSizeChange="onShowSizeChange" />
		</div>
		<a-modal title="移动分组" v-model="moveGroupDialogVisible" :confirm-loading="moveGroupLoading" @ok="moveGroup">
			<a-row :gutter="20">
				<a-col :span="4">任务名称</a-col>
				<a-col :span="20">
					<a-tag v-for="(item, index) in checkedTask" :key="item.id">{{ item.name }}</a-tag>
				</a-col>
			</a-row>
			<a-row :gutter="20" style="margin-top: 20px">
				<a-col :span="4">选择分组</a-col>
				<a-col :span="20">
					<a-select v-model="moveToGroup" style="width: 200px">
						<a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-col>
			</a-row>
		</a-modal>
		<GroupManage :visible.sync="groupManageDialogVisible" @done="done" />
	</div>
</template>

<script>
import { taskListApi, groupListApi, taskCopyApi, taskTopApi, taskDeleteApi, taskMoveApi } from "@/api/rpa";
import GroupManage from "../components/GroupManage";
import { debounce } from "@/utils/tools";
export default {
	components: { GroupManage },
	name: "RpaManage",
	data() {
		return {
			taskList: [],
			groupList: [],
			selectedGroupId: "",
			indeterminate: false,
			checkAll: false,
			loading: false,
			moveGroupDialogVisible: false,
			moveGroupLoading: false,
			moveToGroup: "",
			groupManageDialogVisible: false,
			total: 0,
			pagesize: 10,
			page: 1,
			keywords: "",
		};
	},
	watch: {
		keywords: {
			handler(newVal) {
				this.page = 1;
				debounce(this.getTaskList, 600)();
			},
		},
	},
	created() {
		this.getTaskList();
		this.getGroupList();
	},
	computed: {
		checkedCount() {
			return this.taskList.filter(item => item.checked).length;
		},
		tasks() {
			if (!this.selectedGroupId) {
				return this.taskList;
			} else {
				return this.taskList.filter(item => item.group_id == this.selectedGroupId);
			}
		},
		checkedTask() {
			return this.taskList.filter(item => item.checked);
		},
	},
	methods: {
		getTaskList(e) {
			this.loading = true;
			taskListApi({ page: this.page, pagesize: this.pagesize, name: this.keywords })
				.then(res => {
					if (res.data.code == 200) {
						this.taskList = res.data.data.list.map(item => {
							return {
								...item,
								checked: false,
							};
						});
						this.total = res.data.data.total;
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// 获取分组列表
		getGroupList() {
			groupListApi().then(res => {
				if (res.data.code == 200) {
					this.groupList = res.data.data.list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 全选
		onCheckAllChange(e) {
			let isChecked = e.target.checked;
			if (isChecked) {
				this.checkAll = true;
				this.indeterminate = false;
				this.taskList.forEach(item => {
					item.checked = true;
				});
			} else {
				this.checkAll = false;
				this.indeterminate = false;
				this.taskList.forEach(item => {
					item.checked = false;
				});
			}
		},
		// 单选
		onChangeChange(e) {
			if (this.checkedCount === this.taskList.length) {
				this.indeterminate = false;
				this.checkAll = true;
			} else {
				if (this.checkedCount > 0) {
					this.indeterminate = true;
					this.checkAll = false;
				} else {
					this.indeterminate = false;
					this.checkAll = false;
				}
			}
		},
		// 复制任务
		copyTask(id) {
			this.loading = true;
			taskCopyApi({ id }).then(res => {
				if (res.data.code == 200) {
					this.$message.success("复制成功");
					this.getTaskList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 任务置顶/取消置顶
		topTask(id) {
			this.loading = true;
			taskTopApi({ id }).then(res => {
				if (res.data.code == 200) {
					this.getTaskList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 删除
		deleteTask(id) {
			let target = this.taskList.find(item => item.id == id);
			let _this = this;
			this.$confirm({
				title: "删除任务",
				content: `删除后无法恢复！确定要删除[${target.name}]吗？`,
				onOk() {
					_this.loading = true;
					taskDeleteApi({ id }).then(res => {
						if (res.data.code == 200) {
							_this.$message.success("删除成功");
							_this.getTaskList();
						} else {
							_this.$message.error(res.data.msg);
						}
					});
				},
				onCancel() {},
			});
		},
		// 移动分组
		moveGroup() {
			if (this.moveToGroup) {
				this.moveGroupLoading = true;
				taskMoveApi({ id: this.checkedTask.map(item => item.id), group_id: this.moveToGroup })
					.then(res => {
						if (res.data.code == 200) {
							this.moveGroupDialogVisible = false;
							this.moveToGroup = "";
							this.$message.success("移动成功");
							this.getTaskList();
						} else {
							this.$message.error(res.data.msg);
						}
					})
					.finally(() => {
						this.moveGroupLoading = false;
					});
			}
		},
		pageChange(e) {
			this.getTaskList();
		},
		onShowSizeChange(page, pagesize) {
			this.pagesize = pagesize;
			this.getTaskList();
		},
		done() {
			this.getGroupList();
		},
		search() {
			if (this.keywords) {
				this.getTaskList();
			} else {
				this.$message.error("请输入任务名称");
			}
		},
	},
};
</script>
<style lang="less" scoped>
.RpaManage {
	padding: 20px;
	overflow-y: auto;
	position: relative;
	min-height: calc(100vh - 90px);
	margin-right: 16px;
	.ant-spin {
		position: fixed;
		inset: 0;
		align-items: center;
		justify-content: center;
		background-color: rgba(255, 255, 255, 0.6);
		&.ant-spin-spinning {
			display: flex;
		}
	}
	.header {
		display: flex;
		align-items: center;
		gap: 0 20px;
		.ant-btn {
			display: flex;
			align-items: center;
			img {
				display: block;
				margin-right: 4px;
			}
		}
		.group {
			img {
				transition: all 0.3s;
				filter: grayscale(1);
			}
			&:hover {
				img {
					filter: unset;
				}
			}
		}
	}
	.list {
		margin-top: 20px;
		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.count {
				span {
					color: #3860f4;
				}
			}
		}
		& > .task_container {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: 16px;
			margin-top: 10px;

			li {
				border-radius: 8px;
				background-color: #f9f9f9;
				padding: 12px;
				flex-grow: 0;
				&.topping {
					background-color: #f4f6ff;
				}

				.header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.left {
						display: flex;
						align-items: center;
						gap: 0 6px;
					}
					.right {
						display: flex;
						align-items: center;
						gap: 0 6px;
						img {
							cursor: pointer;
							transition: all 0.2s;
							opacity: 0.6;
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.remark {
					height: 60px;
					line-height: 20px;
					margin: 10px 0;
					color: #999;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
				.footer {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}
		.ant-pagination {
			margin-top: 30px;
			text-align: right;
		}
	}
}
</style>

<style lang="less">
.taskListItemOptionsMenu {
	.ant-popover-inner-content {
		padding: 10px;
		.ant-menu {
			.ant-menu-item {
				margin: 0;
				padding: 0;
				height: 30px;
				display: flex;
				align-items: center;
				padding: 0 10px;
				img {
					margin-right: 4px;
					opacity: 0.6;
				}
			}
		}
	}
}
</style>

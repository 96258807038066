import request from "@/utils/request";

// 分组列表
export const groupListApi = params => {
	return request({
		url: "/client_v1/rpa/group-list",
		method: "get",
		params,
	});
};

// 新增编辑分组信息
export const groupUpdateApi = data => {
	return request({
		url: "/client_v1/rpa/group-update",
		method: "POST",
		data,
	});
};

// 删除分组
export const groupDeleteApi = data => {
	return request({
		url: "/client_v1/rpa/group-delete",
		method: "POST",
		data,
	});
};

// 分组选择
export const groupChooseApi = params => {
	return request({
		url: "/client_v1/rpa/choose-group",
		method: "get",
		params,
	});
};

// 任务流程列表
export const taskListApi = params => {
	return request({
		url: "/client_v1/rpa/list",
		method: "GET",
		params,
	});
};

// 任务置顶
export const taskTopApi = data => {
	return request({
		url: "/client_v1/rpa/topping",
		method: "POST",
		data,
	});
};

// 任务批量移动分组
export const taskMoveApi = data => {
	return request({
		url: "/client_v1/rpa/move-group",
		method: "POST",
		data,
	});
};

// 任务删除
export const taskDeleteApi = data => {
	return request({
		url: "/client_v1/rpa/delete",
		method: "POST",
		data,
	});
};

// 任务复制
export const taskCopyApi = data => {
	return request({
		url: "/client_v1/rpa/copy",
		method: "POST",
		data,
	});
};

// 新增编辑任务流程信息
export const updateTaskApi = data => {
	return request({
		url: "/client_v1/rpa/update",
		method: "POST",
		data,
	});
};

// 任务详情
export const taskDetailApi = params => {
	return request({
		url: "/client_v1/rpa/detail",
		method: "POST",
		params,
	});
};

// 日志列表
export const taskLogApi = params => {
	return request({
		url: "/client_v1/rpa/log-list",
		method: "POST",
		params,
	});
};

// 日志详情
export const taskLogDetailApi = params => {
	return request({
		url: "/client_v1/rpa/log-detail",
		method: "POST",
		params,
	});
};

// 开始任务检测
export const taskOpenCheck = params => {
	return request({
		url: "/client_v1/rpa/open-check",
		method: "POST",
		params,
	});
};

// 任务日志上报
export const taskLogReport = params => {
	return request({
		url: "/client_v1/rpa/log-report",
		method: "POST",
		params,
	});
};

<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="分组管理" :width="800" :footer="null">
		<a-row :gutter="20">
			<a-col :span="3">添加分组</a-col>
			<a-col :span="16" style="display: flex; align-items: center; gap: 0 10px">
				<a-input v-model="name" :maxLength="30">
					<span slot="suffix">{{ name.length }}/30</span>
				</a-input>
				<a-button type="primary" @click="add" :loading="addLoading">添加</a-button>
			</a-col>
		</a-row>
		<a-row :gutter="20">
			<a-col :span="3">搜索</a-col>
			<a-col :span="16" style="display: flex; align-items: center; gap: 0 10px">
				<a-input v-model="keywords" :maxLength="30" allow-clear>
					<a-icon type="search" slot="suffix" @click="search" />
				</a-input>
			</a-col>
		</a-row>
		<div class="info">
			<div class="left">已选择 <span>0</span> 个分组</div>
			<a-button type="danger" @click="batchDelete">
				<a-icon type="delete" />
				<span>批量删除</span>
			</a-button>
		</div>
		<a-table
			:columns="columns"
			:data-source="group_list"
			row-key="id"
			:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
			:loading="table_loading"
			:pagination="false">
			<div slot="name" slot-scope="record">
				<a-input v-if="record.edit" v-model="record.new_name">
					<span slot="suffix">{{ record.new_name.length }}/30</span>
				</a-input>
				<span v-else>{{ record.name }}</span>
			</div>
			<div slot="operate" slot-scope="record">
				<template v-if="record.edit">
					<a-button type="primary" size="small" :disabled="record.name === record.new_name || !record.new_name" @click="confirmEdit(record)"
						>确定</a-button
					>
					<a-button size="small" @click="cancelEdit(record)">取消</a-button>
				</template>
				<template v-else>
					<span style="padding: 0 10px; color: #3860f4; text-decoration: underline; cursor: pointer" @click="record.edit = true">修改</span>
					<span style="padding: 0 10px; color: #ff4d4f; text-decoration: underline; cursor: pointer" @click="del(record)">删除</span>
				</template>
			</div>
		</a-table>
		<a-pagination v-model="page" @change="pageChange" :total="total" show-size-changer @showSizeChange="onShowSizeChange" />
	</a-modal>
</template>

<script>
import { groupUpdateApi, groupListApi, groupDeleteApi } from "@/api/rpa";
import { debounce } from "@/utils/tools";
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	name: "GroupManage",
	data() {
		return {
			group_list: [],
			name: "",
			keywords: "",
			addLoading: false,
			columns: [
				{
					title: "分组名称",
					scopedSlots: { customRender: "name" },
				},
				{
					title: "操作",
					scopedSlots: { customRender: "operate" },
					fixed: "right",
					width: 160,
				},
			],
			selectedRowKeys: [],
			total: 0,
			pagesize: 10,
			page: 1,
			table_loading: false,
		};
	},
	computed: {
		groups() {
			return [];
		},
	},
	watch: {
		visible: {
			handler(newVal) {
				if (newVal) {
					this.getGroupList();
				}
			},
		},
		keywords: {
			handler(newVal) {
				this.page = 1;
				debounce(this.getGroupList, 600)();
			},
		},
	},
	methods: {
		// 获取分组列表
		getGroupList() {
			this.table_loading = true;
			groupListApi({ page: this.page, pagesize: this.pagesize, name: this.keywords })
				.then(res => {
					if (res.data.code == 200) {
						this.group_list = res.data.data.list.map(item => {
							return {
								...item,
								new_name: item.name,
								edit: false,
							};
						});
						this.total = res.data.data.total;
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {
					this.table_loading = false;
				});
		},
		ok() {},
		cancel() {
			this.$emit("update:visible", false);
			this.$emit("done");
		},
		add() {
			if (!this.name) {
				return this.$message.error("请输入分组名称");
			}
			this.addLoading = true;
			groupUpdateApi({ name: this.name })
				.then(res => {
					if (res.data.code == 200) {
						this.$message.success("添加成功");
						this.getGroupList();
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {
					this.addLoading = false;
					this.name = "";
				});
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		pageChange(e) {
			this.getGroupList();
		},
		onShowSizeChange(page, pagesize) {
			this.pagesize = pagesize;
			this.getGroupList();
		},
		search() {
			if (!this.keywords) {
				return this.$message.error("请输入搜索关键词");
			}
			this.getGroupList();
		},
		confirmEdit(record) {
			record.edit = false;
			record.name = record.new_name;
			groupUpdateApi({ id: record.id, name: record.new_name }).then(res => {
				if (res.data.code == 200) {
					this.getGroupList();
				}
			});
		},
		cancelEdit(record) {
			record.edit = false;
			record.new_name = record.name;
		},
		del(record) {
			let _this = this;
			this.$confirm({
				title: "删除分组",
				content: `删除后无法恢复！确定要删除分组[${record.name}]吗？`,
				onOk() {
					groupDeleteApi({ id: record.id }).then(res => {
						if (res.data.code == 200) {
							_this.getGroupList();
							_this.$message.success("删除成功");
						} else {
							_this.$message.error(res.data.msg);
						}
					});
				},
				onCancel() {},
			});
		},
		batchDelete() {
			if (this.selectedRowKeys.length == 0) {
				return this.$message.error("请选择要删除的分组");
			}
			this.table_loading = true;
			groupDeleteApi({ id: this.selectedRowKeys }).then(res => {
				if (res.data.code == 200) {
					this.selectedRowKeys = [];
					this.$message.success("删除成功");
					this.getGroupList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
		.ant-modal-body {
			.ant-row {
				display: flex;
				align-items: center;
				& + .ant-row {
					margin-top: 20px;
				}
			}
			.anticon-search {
				font-size: 18px;
				cursor: pointer;
				svg {
					display: block;
				}
			}
			.info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 30px;
			}
			.ant-table-wrapper {
				margin-top: 20px;
				.ant-table-tbody {
					tr {
						height: 40px;
						td {
							height: 40px;
							padding-top: 0px;
							padding-bottom: 0px;
						}
					}
				}
			}
			.ant-pagination {
				margin-top: 30px;
				text-align: right;
			}
		}
	}
}
</style>
